<template>
  <div class="i-wrap">
    <p-header spacer border title="红字增值税专用发票信息表">
      <div class="content-select" >
        <label>当前组织：</label>
        <organization-select  :model="selectForm"></organization-select>
      </div>

    </p-header>
    <div class="i-main">
      <div class="red-wrap">
        <div class="red-header">
          <div class="header-info">
            <div></div>
            <div class="header-title">
              红字增值税<span>专用</span>发票信息表
            </div>
            <div>
              <el-button @click="handleChangeTaxStatus" :type="typeByTaxStatus">{{ hadTax | fmtTaxStatus }}</el-button>
              <el-button type="primary" @click="handleSubmit" :disabled="btnDisabled">上传</el-button>
            </div>
          </div>

          <div class="header-items">
            <div>
              <label>填开日期：</label>
              <span>{{fmtTime(info.invoiceTime)}}</span>
            </div>
            <div>
              <label>申请方经办人：</label>
              <span>{{nickName}}</span>
            </div>
          </div>
        </div>
        <div class="red-content">
          <div class="red-info">
            <div>
              <div class="info-label">销售方</div>
              <div class="info-area">
                <div>
                  <label class="required">名 称：</label>
                  <el-autocomplete class="sellerName" v-model="info.sellerName"
                                   :fetch-suggestions="querySellerNameAsync"
                                   :trigger-on-focus="false"
                                   @select="handleSelect" placeholder="请输入销售方姓名">
                  </el-autocomplete>
                </div>
                <div>
                  <label>税 号：</label>
                  <el-input v-model="info.sellerTaxNo"></el-input>
                </div>
                <div>
                  <label>地址、电话:</label>
                  <el-input v-model="info.sellerAddressTel"></el-input>
                </div>
                <div>
                  <label>开户行及账号:</label>
                  <el-input v-model="info.sellerBankAccount"></el-input>
                </div>
              </div>
            </div>
            <div>
              <div class="info-label">购买方</div>
              <div class="info-area">
                <div>
                  <label>名 称：</label>
                  <el-input v-model="info.buyerName" ></el-input>
                </div>
                <div>
                  <label>税 号：</label>
                  <el-input v-model="info.buyerTaxNo" ></el-input>
                </div>
                <div>
                  <label>地址、电话:</label>
                  <el-input v-model="info.buyerAddressTel"></el-input>
                </div>
                <div>
                  <label>开户行及账号:</label>
                  <el-input v-model="info.buyerBankAccount"></el-input>
                </div>
              </div>
            </div>
          </div>
          <div class="red-items">
            <retail-invoice-open-goods-list
              ref="retailInvoiceGoodsList"
              biz-type="red"
              :tax-status="hadTax"
              :goods-list="goodsList"
              :goods-index="goodsIndex"
              @handleFocusTd="handleFocusTd"
              @nameBlur="handleMerchandiseNameBlur"
              @handleShowGoods="handleShowGoods"
              @handleInsertMerchandise="handleInsertMerchandise"
              @dataSelect="handleCurrentGoods"
              @reviewInvoice="handleReviewInvoice"
              @changeIndex="changeIndex">
            </retail-invoice-open-goods-list>
          </div>
          <div class="red-remark">
            <div class="remark-item">
              <div class="remark-title">说明</div>
              <div class="remark-content">
                <div class="red-source">
                  <el-radio v-model="source" label="buyer">购买方申请</el-radio>
                  <el-radio v-model="source" label="seller" disabled>销售方申请</el-radio>
                </div>
                <div class="red-deduction">
                  <el-radio v-model="info.deductionType" :label="true" :disabled="!info.deductionType">已抵扣</el-radio>
                  <el-radio v-model="info.deductionType" :label="false" :disabled="info.deductionType">未抵扣</el-radio>
                </div>
              </div>
            </div>
            <div class="remark-item">
              <div class="remark-title">对应蓝字专用发票密码区内打印的发票信息</div>
              <div class="remark-info">
                <div>
                  <label>发票代码：</label>
                  <span>{{info.invoiceCode}}</span>
                </div>
                <div>
                  <label>发票号码：</label>
                  <span>{{info.invoiceNo}}</span>
                </div>
                <div>
                  <label>发票种类：</label>
                  <span>{{info.billingType | fmtBillingType}}</span>
                </div>
                <div>
                  <label>开票时间：</label>
                  <span>{{info.invoiceTime | fmtTime}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 新增商品 -->
    <el-dialog append-to-body title="新增" :visible.sync="editVisible" :show-close="false" :destroy-on-close="true" @close="handleCancelEditDialog">
      <merchandise-edit v-if="editVisible" :merchandise="merchandise" :is-retail="true"
                        :content="taxPreferentialContents" scene="Billing"
                        @edit-finish="handleMerchandiseEditFinish"
                        @handleCancelEditDialog="handleCancelEditDialog"></merchandise-edit>
    </el-dialog>
    <!-- 选择商品 -->
    <el-dialog append-to-body title="选择商品" :visible.sync="dialogGoodsListVisible" width="804px" :show-close="false">
      <merchandise-list-dialog v-if="dialogGoodsListVisible"
                               @handleCurrentGoods="handleCurrentGoods"></merchandise-list-dialog>
    </el-dialog>
  </div>
</template>

<script>
import { getBuyerNames as getSellerNames, getEquipmentByOrgId, submitApply } from '@/service/invoice-open'
import OrganizationSelect from '@/components/widgets/OrganizationSelect'
import { FmtEnum } from '@/util/enumUtil'

import moment from 'moment'
import PHeader from '@/components/pdt/PHeader'
import RetailInvoiceOpenGoodsList from '../invoice-issue/retail/components/RetailInvoiceOpenGoodsList'
import MerchandiseEdit from '@/views/system/merchandise/MerchandiseEdit'
import MerchandiseListDialog from '../invoice-issue/components/MerchandiseListDialog'

export default {
  name: 'BillingRedNoticeInfo',
  components: { PHeader, RetailInvoiceOpenGoodsList, MerchandiseEdit, MerchandiseListDialog,OrganizationSelect },
  watch:{
    "selectForm.orgId":function (){
      this.handleInitBuyer (this.selectForm.orgId)
    }
  },
  computed: {
    // 含税按钮状态
    typeByTaxStatus () {
      return this.hadTax ? 'default' : 'primary'
    },
    btnDisabled () {
      const index = this.goodsList.findIndex(i => i.taxClassCode == '' || i.taxClassCode == null)
      const amount = this.goodsList.findIndex(i => i.sumAmount == '' || i.sumAmount == 0 || i.sumAmount == null)
      if (index > -1 || amount > -1) {
        return true
      }
      return !(this.info.sellerName && this.info.sellerTaxNo)
    }
  },
  filters: {
    fmtTaxStatus (val) {
      return val ? '含税' : '不含税'
    },
    // 格式化开具类型
    fmtBillingType (val) {
      const types = JSON.parse(localStorage.getItem('enums'))['BillingType']
      return FmtEnum(types, val)
    },
    // 格式化时间
    fmtTime (val) {
      return moment(val).format('YYYY年MM月DD日')
    }
  },
  data () {
    return {
      nickName: localStorage.getItem('nickName'),
      hadTax: true, // 是否含税显示
      source: 'buyer', // 申请来源： 购买方/销售方
      selectForm: {
        orgId: localStorage.getItem('orgId') ? Number(localStorage.getItem('orgId')) : '',
        name: ''
      },
      info: {
        invoiceCode: '',
        invoiceNo: '',
        billingType: 'Zzzp',
        deductionType: '',
        redSource: 'buyer', // 申请来源：购买方申请
        invoiceTime: "",
        sellerName: '',
        sellerTaxNo: '',
        buyerName: '',
        buyerTaxNo: '',
        buyerAddressTel:'',
        buyerBankAccount:'',
        equipmentId: '',
        // 商品明细
        items: []
      },
      merchandise: {}, //新增商品
      editVisible: false, // 新增商品弹窗显示
      dialogGoodsListVisible: false, // 商品选择列表弹窗
      taxPreferentialContents: [], // 商品优惠政策字典
      goodsList: [], // 明细列表
      goodsIndex: 0, //商品标识符
      currentGoodsIndex: 0, // 当前选中商品下标
      sellerNames: []
    }
  },
  created () {
    this.info = { ...this.info, ...this.$route.query }
    this.info['deductionType'] = this.info['deductionType'] == "1"
    this.handleInitBuyer()
    this.handleInitEquipment()
    this.getDicts('o-merchandise-tax').then(res => this.taxPreferentialContents = res.data)
  },
  methods: {
    // 上传红字通知单
    async handleSubmit () {
      // 校验税盘编号
      this.info['items'] = this.goodsList
      this.info['invoiceTime']=this.fmtTime(this.info.invoiceTime)
      this.info['hadTax']=this.hadTax;
      this.$refs.retailInvoiceGoodsList.computedInvoicePrice()
      const { success, data } = await submitApply(this.info)
      if (success) {
        await this.$router.push('/open/red-notice')
      }
    },
    // 获取默认设备
    async handleInitEquipment () {
      const { success, data } = await getEquipmentByOrgId({ orgId: localStorage.getItem('orgId') })
      if (success) {
        this.equipment = data
        this.info.equipmentId = data.id
      } else {
        this.toast('未找到默认开票设备', 'error')
      }

    },
    // 初始化购方信息
    async handleInitBuyer (orgId) {
      if(orgId==null){
        orgId=localStorage.getItem('orgId');
      }
      let org = this.$store.state.user.organizations.find(i => i.id == orgId)
      const { success, data } = await getEquipmentByOrgId({ orgId: orgId })
      if (success) {
        this.equipment = data
        this.info.equipmentId = data.id
      } else {
        this.toast('未找到默认开票设备', 'error')
      }
      if (!org) {
        this.toast('未找到购方信息', 'error')
        return
      }
      this.info.buyerName = org.name
      this.info.buyerTaxNo = org.taxNo
      this.info.buyerAddressTel = org.addressTel
      this.info.buyerBankAccount = org.bankAccount
    },
    // 触发模糊搜索
    async querySellerNameAsync (value, fn) {
      const { success, data } = await getSellerNames({ keyword: value })
      if (success) this.sellerNames = data.map(i => i.value = i.name)
      fn(data)
    },
    // 切换含税 / 不含税
    handleChangeTaxStatus () {
      this.hadTax = !this.hadTax
    },
    // 模糊搜索下拉框的勾选
    handleSelect (val) {
      this.info.sellerTaxNo = val.taxCode
      this.info.sellerName = val.name
      this.info.sellerAddressTel = val.addressPhone
      this.info.sellerBankAccount = val.bankAccount
    },
    changeIndex (value) {
      this.goodsIndex += value
    },
    // 输入货物清单通知
    handleMerchandiseNameBlur (row, merchandises) {
      //  失去焦点行下标
      this.currentGoodsIndex = row.index
      if (!row.taxClassCode) {
        this.handleInsertMerchandise(JSON.parse(JSON.stringify(row)))
        return
      }
      this.handleCurrentGoods(row)
    },
    // 商品选中
    handleCurrentGoods (row) {
      let goods = this.goodsList.find(i => i.index == this.currentGoodsIndex)
      if (!goods) return

      if (row.specification) {
        goods.specification = row.specification
      }
      if (row.unit) {
        goods.unit = row.unit
      }
      // 免税-不征税明细
      if (row.taxPreferentialContent == '免税' || row.taxPreferentialContent == '不征税') {
        goods['taxRateModel'] = row.taxPreferentialContent
        goods['taxRate'] = 0
      } else {
        goods['taxRate'] = Number(row.taxRate)
        goods['taxRateModel'] = Number(row.taxRate)
      }
      goods.price = row.price == 0 ? null : row.price
      goods.uniqueCode = row.uniqueCode
      goods.taxClassCode = row.taxClassCode

      // 显示值
      if (!row.merchandiseName && row.untaxMerchandiseName) {
        row.merchandiseName = row.untaxMerchandiseName
      }
      goods.merchandiseName = /\*/.test(row.merchandiseName) ? row.merchandiseName : `*${row.taxClassName}*${row.merchandiseName}`

      goods.notFirst = true
      goods.taxClassName = row.taxClassName
      goods.taxVersionNo = row.taxVersionNo
      goods.isTaxPreferential = row.isTaxPreferential
      goods.taxPreferentialContent = row.taxPreferentialContent
      goods.visible = false

      this.$refs.retailInvoiceGoodsList.handleKeyupGoodsPrice(goods)
      // 焦点移动
      //this.$refs.invoiceGoodsList.focusNext(goods.index)
      this.dialogGoodsListVisible = false
      let inputDom = document.getElementById('input-' + row.index)
      inputDom ? inputDom.blur() : null
    },
    // 新增商品信息弹窗
    handleInsertMerchandise (row) {
      this.merchandise.index = row.index
      this.merchandise.merchandiseName = row.merchandiseName
      this.editVisible = true
    },
    // 表格项被焦点
    handleFocusTd (val) {
      this.currentGoodsIndex = val.index
      // 商品明细已经填写了 "名称 & 金额 & 税率"
      if (val.merchandiseName && val.amount && val.taxRate != null) {
        this.hadTdFocus = val.focus
        this.discountStatus = val.itemProperty > 0 ? true : false
      } else {
        this.hadTdFocus = false
      }
    },
    // 新增商品信息弹窗 关闭的回调
    handleCancelEditDialog () {
      this.editVisible = false
      this.merchandise = {
        orgId: null,
        uniqueCode: undefined,
        merchandiseName: '',
        taxRate: null,
        merchandiseCategory: '',
        isTaxPreferential: '',
        taxPreferentialContent: '',
        taxClassName: '',
        taxClassCode: '',
        taxRateModel: ''
      }
      this.$refs.retailInvoiceGoodsList.focusInput(this.currentGoodsIndex)
    },
    // 新增商品完成
    handleMerchandiseEditFinish (row) {
      this.editVisible = false
      this.handleCurrentGoods(row)
    },
    // 展开商品选择弹窗
    handleShowGoods (goods) {
      this.dialogGoodsListVisible = true
      this.currentGoodsIndex = goods.index
    },
    // 发票价税补全
    handleReviewInvoice (invoicePrice) {
      this.info = { ...this.info, ...invoicePrice }
    },
    fmtTime (val) {
      return this.$moment(val).format('YYYY-MM-DD')
    }
  }
}
</script>

<style lang="scss" scoped>
  .i-wrap {
    min-height: 100%;
    background-color: #FFFFFF;
  }

  .i-main {
    display: flex;
    justify-content: center;
    padding: 24px;
  }

  .red-wrap {
    display: flex;
    padding: 24px;
    width: 1140px;
    color: #9D5224;
    align-items: center;
    flex-direction: column;
    border: 1px solid #E5E5E5;

    .red-header {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 18px;
      flex-direction: column;

      .header-info {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
      }

      .header-title {
        font-size: 32px;
        margin-bottom: 28px;
        border-bottom: 4px double;

        span {
          color: #3D94FF;
        }
      }

      .header-items {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        div:first-child {
          margin-right: 100px;
        }

        span {
          padding-left: 10px;
          color: #333333;
        }
      }
    }

    .red-content {
      width: 100%;
      display: flex;
      flex-direction: column;

      .red-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 20px;
        justify-content: space-between;

        .info-label {
          width: auto;
          display: flex;
          padding: 24px 11px;
          letter-spacing: 6px;
          align-items: center;
          justify-content: center;
          border-right: 1px solid;
          writing-mode: vertical-lr;
        }

        .info-area {
          width: 100%;
          padding: 24px;

          label {
            width: 110px;
            color: #9D5224;
            text-align: left;
            display: inline-block;
            vertical-align: bottom;
          }

          .sellerName {
            width: 340px;
          }

          .sellerName ::v-deep .el-input {
            width: 100%;
            border-bottom: 1px solid #979797;
          }

          .el-input {
            width: 340px;
            border-bottom: 1px solid #979797;
          }

          ::v-deep .el-input__inner {
            border: none;
            border-radius: 0;
            background-color: #FFFFFF;
          }

          .required:before {
            margin-left: -10px;
            content: "*";
            color: #F5222D;
          }
        }
      }

      .red-info > div {
        width: 48%;
        display: flex;
        border: 1px solid;
        flex-direction: row;
      }
    }

    .red-remark {
      height: 170px;
      display: flex;
      margin-top: 20px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .remark-item {
        width: 50%;
        height: 100%;
        display: flex;
        font-size: 14px;
        border: 1px solid;
        flex-direction: row;
        justify-content: flex-start;

        .remark-title {
          display: flex;
          max-width: 60px;
          padding: 10px 8px;
          word-break: break-word;
          border-right: 1px solid;
          justify-content: center;
          height: calc(100% - 20px);
          writing-mode: vertical-lr;
        }
      }

      .remark-item:last-child {
        border-left: none;
      }

      .remark-content {
        display: flex;
        padding: 20px;
        position: relative;
        flex-direction: row;

        .red-source {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        }

        .red-deduction {
          display: flex;
          margin-top: 50px;
          margin-left: 20px;
          position: absolute;
        }
      }

      .remark-info {
        display: flex;
        padding: 20px;
        flex-direction: column;
        justify-content: space-between;

        span {
          color: #333333;
        }
      }
    }

    .businessSign {
      margin-left: 100px;
    }
  }
</style>
